import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {AdminComponent} from "./admin/admin.component";
import {ManagerComponent} from "./manager/manager.component";
import {AuthenticationGuard} from "./guards/authentication.guard";
import {ErrorComponent} from "./error/error.component";
import {DocumentationComponent} from "./documentation/documentation.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule)
  },
  {
    path: 'error/:code',
    component: ErrorComponent
  },
  {
    path: 'documentation/:token',
    component: DocumentationComponent
  },
  {
    path: '**',
    redirectTo: 'manager',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
