import {Component, Input, OnInit} from '@angular/core';
import {GeocodeHttpService} from "@services/http/geocode-http.service";

@Component({
  selector: 'app-geocode-reverse-label',
  templateUrl: './geocode-reverse-label.component.html',
  styleUrls: ['./geocode-reverse-label.component.scss']
})
export class GeocodeReverseLabelComponent implements OnInit {

  @Input() public geojson!: string;
  public label: string = '';

  public constructor(
      private geocodeHttpService: GeocodeHttpService
  ) { }

  public ngOnInit(): void {
    if (!/^{"type":"(Point)","coordinates":\[.*\]}$/.test(this.geojson)) {
      return;
    }
    const geojson = JSON.parse(this.geojson)

    this.geocodeHttpService.reverse(geojson.coordinates[1], geojson.coordinates[0]).subscribe({
      next: (label: string) => {
        this.label = label;
      }
    });
  }
}
