import {Component, ElementRef, EventEmitter, HostListener, Output, ViewChild} from '@angular/core';
import {Category} from "@models/interfaces";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss']
})
export class CategorySelectorComponent {

  @Output() public categorySelected: EventEmitter<Category> = new EventEmitter();
  @ViewChild('templateCategorySelectorModal') public modal!: ElementRef;

  private modalRef!: NgbModalRef;

  public constructor(
      private modalService: NgbModal
  ) { }

  @HostListener('click', ['$event.target'])
  public openModal(): void {
    this.modalRef = this.modalService.open(this.modal, {size: 'lg', centered: true, animation: false})
  }

  public selected(category: Category): void {
    if (this.modalRef !== undefined) {
      this.modalRef.close();
    }
    if (!category.default) {
      this.categorySelected.emit(category);
    }
  }
}
