<button class="btn btn-sm btn-success text-white mx-1"
        type="button"
        [disabled]="disable"
        (click)="trigger.emit()"
        [ngbTooltip]="text"
        placement="left-bottom"
        [attr.aria-label]="text"
        tooltipClass="tooltip-custom">
    <i-bs [name]="icon" width="15" height="15" *ngIf="!loader; else spinner"></i-bs>
    <ng-template #spinner>
        <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
    </ng-template>
</button>



