import {BaseHttpService} from "./base-http.service";
import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";

type TokenHttpResponse = {
  token: string;
};

@Injectable({
  providedIn: 'root'
})
export class LoginHttpService extends BaseHttpService {
  public auth(username: string, password: string): Observable<TokenHttpResponse> {
    return this.httpClient.post<TokenHttpResponse>(
      this.host + '/login/user',
      {
        username,
        password
      }
    ).pipe(
      tap((response: TokenHttpResponse) => {
        this.sessionService.start(response.token);
      })
    );
  }
}
