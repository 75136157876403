import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaginationComponent {
  @Output() public pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() public total!: number;
  @Input() public pageSize!: number;
  @Input() public currentPage!: number;
}
