<div>
    <div class="text-end">
        <app-medium-button [text]="'selectCustomerPlace' | translate" (trigger)="openModal()"></app-medium-button>
    </div>
    <div class="py-2" *ngIf="customerPlaceSelected.length">
        <label class="small text-secondary">
            {{'customerPlaceAccessInfo' | translate}}
        </label>
    </div>
    <div class="my-3">
        <!-- Selected table -->
        <table class="table table-striped">
            <tbody>
                <tr class="small" *ngFor="let customerPlace of customerPlaceSelected">
                    <td>
                        {{customerPlace.code}}
                    </td>
                    <td class="w-auto">
                        {{customerPlace.name}}
                    </td>
                    <td class="py-0">
                        <div class="d-flex justify-content-end">
                            <app-small-button [text]="'delete' | translate" [icon]="'trash'" (trigger)="remove(customerPlace)"></app-small-button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Modal selection -->
<ng-template #customerPlaceSelectionModal let-modal>
    <app-view-wrapper-modal [loader]="loader" (closeEmitter)="modal.close()">
        <div content>
            <table class="table table-striped">
                <tbody>
                    <tr class="small" *ngFor="let customerPlace of customerPlaceListAvailable">
                        <td>
                            {{customerPlace.code}}
                        </td>
                        <td>
                            {{customerPlace.name}}
                        </td>
                        <td class="py-0">
                            <div class="d-flex justify-content-end">
                                <app-medium-button [text]="'select' | translate" (trigger)="select(customerPlace)"></app-medium-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-view-wrapper-modal>
</ng-template>
