<div class="p-3">
    <div class="row align-items-center">
        <h1 class="h1 text-uppercase text-success">
            <i-bs [name]="'emoji-frown'" width="30" height="30"></i-bs>
            Error {{code === 0 ? 500 : code}}
        </h1>
    </div>
    <div class="my-3">
        <app-medium-button [text]="'goBack' | translate" (trigger)="goBack()"></app-medium-button>
    </div>
</div>
