import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {ErrorHandlingService} from "@services/error-handling.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    public constructor(
        private errorHandlingService: ErrorHandlingService
    ) {
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status !== 409) {
                        this.errorHandlingService.handleHttp(error);
                    }
                    return throwError(() => error);
                })
            );
    }
}
