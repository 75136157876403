import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerPlaceHttpService} from "@services/http/customer-place-http.service";
import {HttpErrorResponse} from "@angular/common/http";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ErrorHandlingService} from "@services/error-handling.service";
import {CustomerPlace} from "@models/interfaces";

@Component({
  selector: 'app-customer-place-selector',
  templateUrl: './customer-place-selector.component.html',
  styleUrls: ['./customer-place-selector.component.scss']
})
export class CustomerPlaceSelectorComponent implements OnInit {

  @ViewChild('customerPlaceSelectionModal') public modal!: ElementRef;
  @Input() public presetCustomerPlaceList!: CustomerPlace[];
  @Output() public customerPlaceSelection: EventEmitter<CustomerPlace[]> = new EventEmitter();

  public customerPlaceListAvailable: CustomerPlace[] = [];
  public customerPlaceSelected: CustomerPlace[] = [];
  public loader: boolean = false;
  private modalRef!: NgbModalRef;

  public constructor(
      private customerPlaceHttpService: CustomerPlaceHttpService,
      private modalService: NgbModal,
      private errorHandlingService: ErrorHandlingService
  ) { }

  public ngOnInit(): void {
    this.loader = true;
    this.customerPlaceHttpService.list().subscribe({
      next: (response: CustomerPlace[]) => {
        this.customerPlaceListAvailable = response;
        this.loader = false;
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error)
    });
    if (this.presetCustomerPlaceList) {
      this.customerPlaceSelected = this.presetCustomerPlaceList;
      this.customerPlaceSelection.emit(this.customerPlaceSelected);
    }
  }

  public openModal(): void {
    this.modalRef = this.modalService.open(this.modal, {size: 'lg', centered: true, animation: false});
  }

  public select(customerPlace: CustomerPlace): void {
    const found: CustomerPlace|undefined = this.customerPlaceSelected.find(
        (element: CustomerPlace) => {
          return element.code === customerPlace.code;
        }
    );
    if (found === undefined) {
      this.customerPlaceSelected.push(customerPlace);
      this.customerPlaceSelection.emit(this.customerPlaceSelected);
    }
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  public remove(customerPlace: CustomerPlace): void {
    this.customerPlaceSelected = this.customerPlaceSelected.filter(
        (element: CustomerPlace) => {
            return customerPlace.code !== element.code
        }
    );
    this.customerPlaceSelection.emit(this.customerPlaceSelected);
  }
}
