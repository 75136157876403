export enum MediaResourceTypeEnum {
    RichText = 'rich_text',
    Video = 'video',
    Image = 'image',
    Audio = 'audio',
    File = 'file',
}
export enum ClientAlertActionTypeEnum {
    Redirection = 'Redirection',
    ShowContent = 'ShowContent'
}
export enum ClientAlertTriggerTypeEnum {
    ContentAccess = 'ClientAlertContentAccess',
    StartVisit = 'ClientAlertStartVisit'
}

export type Platform = 'web' | 'android' | 'ios';
export type TreeNodeDisplayMode = 'list' | 'cards';
export type PublicationReleaseEnvironment = 'qa' | 'prod';
export type PublicationReleasedStatus = 'pending' | 'enabled' | 'disabled' | 'failed';
export type TranslationsFileContent = {[identifier: string]: string};
export type WidgetObjectPropertyAttributeType = 'boolean' | 'hexadecimal_color' | 'image_area' | 'numeric';
export type WidgetType = 'collection' | 'object';
export type MediaResourceType = MediaResourceTypeEnum.Image | MediaResourceTypeEnum.Video | MediaResourceTypeEnum.Audio | MediaResourceTypeEnum.File | MediaResourceTypeEnum.RichText | '';
export type PublishableStatusType = 'disabled' | 'developing' | 'pending_to_review' | 'qa' | 'public';

export interface Language {
    iso_code: string;
    description: string;
    created_at?: string;
    updated_at?: string;
}
export interface CustomerPlace {
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    categories: Category[];
    code: string;
    name: string;
    languages: CustomerPlaceLanguage[];
    tours?: Tour[];
    maps?: Map[];
    contents?: Content[];
    type: string;
    location_geojson: GeoJson|null;
    users?: User[]
}
export interface CustomerPlaceLanguage {
    iso_code: string;
    position: number;
    is_main: boolean;
    created_at?: string;
    updated_at?: string;
}
export interface Client {
    id: string;
    secret: string;
    name: string;
    revoked: boolean;
    created_at?: string;
    updated_at?: string;
    access_count?: number;
    customer_places: ClientCustomerPlace[];
    allow_receive_tracking_data: boolean;
    type: 'script' | 'web' | 'app';
    host: string|null;
}
export interface Role {
    name: string;
    permissions: string[];
}
export interface CustomerPlaceAccessManagement {
    customer_place: CustomerPlace;
    created_at?: string;
    updated_at?: string;
}
export interface User {
    name: string;
    username: string;
    created_at?: string;
    updated_at?: string
    password?: string;
    enabled: boolean;
    email: string;
    roles: Role[];
    customer_place_access_management: CustomerPlaceAccessManagement[];
}
export interface PublicationReleased {
    consumed_count: number;
    uuid: string;
    completed_at: string|null;
    created_at?: string;
    updated_at?: string;
    has_package_media_resources_files: boolean;
    add_minified_files_in_package: boolean;
    file_segregation_by_language: boolean;
    package_bytes: number;
    user: User;
    status: PublicationReleasedStatus;
    environment: PublicationReleaseEnvironment;
    languages: PublicationReleasedLanguage[];
}
export interface PublicationReleasedLanguage {
    language_iso_code: string;
    package_bytes: number;
    tours: PublicationReleasedLanguageTour[];
}
export interface PublicationReleasedLanguageTour {
    description: string;
    tour_uuid: string;
    package_bytes: number;
}
export interface ClientCustomerPlace {
    customer_place: CustomerPlace;
    created_at?: string;
    updated_at?: string;
}
export interface TranslationsFile {
    uuid: string;
    content: TranslationsFileContent;
    language_iso_code: string;
    published_at: string|null;
    created_at?: string;
    updated_at?: string;
}
export interface TemplateContent {
    key: string;
    description: string;
    templates_widget: TemplateContentTemplateWidget[];
    created_by?: string;
    created_at?: string;
    default: boolean;
    templates_widget_keys?: string[];
}
export interface TemplateContentTemplateWidget {
    template_widget: TemplateWidget;
}
export interface Content {
    description: string;
    status: PublishableStatusType;
    created_at?: string;
    updated_at?: string;
    widgets: WidgetListElement[];
    customer_place_code?: string;
    created_by?: string;
    uuid?: string;
    template?: TemplateContent;
    template_description?: string;
    categories: Category[];
    total_reports_missing_translations?: number;
    locator_codes: ContentLocatorCode[];
}
export interface ContentLocatorCode {
    id?: number;
    code: string;
    created_at?: string;
    updated_at?: string;
}
export interface TemplateWidget {
    key: string;
    description: string;
    taxonomy: WidgetType;
    properties: TemplateWidgetProperty[];
    created_by?: string;
}
export interface TemplateWidgetPropertyAttribute {
    key: string;
    type: WidgetObjectPropertyAttributeType;
    description: string;
    default_value: any;
}
export interface TemplateWidgetProperty {
    key: string;
    description: string;
    attributes: TemplateWidgetPropertyAttribute[];
    constraints: TemplateWidgetPropertyConstraints;
    position: number;
}
export interface TemplateWidgetPropertyConstraints {
    max_length: number|null;
    type_media_resource_allowed: MediaResourceType;
    optional: boolean;
    mimetype_allowed: string[];
    allow_generate_locution: boolean;
    use_in_other_languages: boolean;
}
export interface WidgetListElement {
    position: number;
    widget: Widget;
}
export interface Widget<T = WidgetObject|WidgetCollection> {
    id?: number;
    customer_place_code?: string;
    type: WidgetType;
    uuid: string;
    template: TemplateWidget;
    props: T;
}
export interface WidgetCollection {
    widget_objects: WidgetCollectionWidgetObject[];
}
export interface WidgetCollectionWidgetObject {
    position: number;
    widget_object: WidgetObject;
}
export interface WidgetObject {
    properties: WidgetObjectProperty[];
}
export interface WidgetObjectPropertyAttribute {
    template_widget_property_attribute: TemplateWidgetPropertyAttribute;
    value: any;
}
export interface WidgetObjectProperty {
    languages: WidgetObjectPropertyLanguage[];
    attributes: WidgetObjectPropertyAttribute[];
    template_widget_property: TemplateWidgetProperty;
}
export interface WidgetObjectPropertyLanguage {
    media_resource: MediaResource|null;
    language_iso_code: string;
}
export interface Tour {
    created_at?: string;
    updated_at?: string;
    widget: Widget<WidgetObject>|null;
    description: string;
    status: PublishableStatusType;
    customer_place_code?: string;
    created_by?: string;
    uuid?: string;
    minutes_duration: number|null;
    navigation_tree: NavigationTree;
    total_contents: number;
    accessibility_configuration: TourAccessibilityConfiguration[];
    position: number;
    maps: TourMap[];
    languages_available: TourLanguagesAvailable[];
}
export interface TourMap {
    map: Map;
}
export interface TourAccessibilityConfiguration {
    enable_subtitles: boolean;
    enable_sign_language: boolean;
    enable_audio_description: boolean;
    language_iso_code: string;
}
export interface NavigationTree {
    id?: string;
    tree_node: TreeNode;
    created_at?: string;
    updated_at?: string;
}
export interface TreeNodeLanguageExclusion {
    language_iso_code: string;
}
export interface TreeNode {
    id?: number;
    uuid: string;
    content_uuid: string|null;
    children: TreeNode[];
    position: number;
    language_exclusion: TreeNodeLanguageExclusion[];
    interactive: boolean;
    display_mode: TreeNodeDisplayMode;
    keyboard_code: string|null;
}
export interface Map {
    created_at?: string;
    updated_at?: string;
    description: string;
    status: PublishableStatusType;
    customer_place_code?: string;
    created_by?: string;
    uuid?: string;
    floor: number|null;
    media_resource: FileMediaResource|null;
    contents: MapContent[];
    layers: MapLayer[];
}
export interface MapContent {
    id?: number;
    x: number;
    y: number;
    content_uuid: string|null;
}
export interface MapLayer {
    id?: number;
    x: number;
    y: number;
    languages: MapLayerLanguage[];
}
export interface MapLayerLanguage {
    id?: number;
    language_iso_code: string|null;
    media_resource: MediaResource;
}
export interface Category {
    code: string;
    description: string;
    created_at?: string;
    updated_at?: string;
    created_by?: string|null;
    default: boolean;
}
export interface MediaResource {
    id?: number;
    uuid: string;
    type: MediaResourceType;
    language_iso_code?: string|null;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    keywords?: string;
    customer_place_code?: string;
}
export interface RichTextMediaResource extends MediaResource {
    props: {
        content: string;
        mimetype: string;
    };
}
export interface VideoMediaResource extends MediaResource {
    props: {
        default: FileUploaded;
        small: FileUploaded;
        poster: FileUploaded|null;
    };
}
export interface ImageMediaResource extends MediaResource {
    props: {
        default: FileUploaded;
        medium: FileUploaded;
        small: FileUploaded;
    };
}
export interface AudioMediaResource extends MediaResource {
    props: {
        file: FileUploaded;
    };
}
export interface FileMediaResource extends MediaResource {
    props: {
        file: FileUploaded;
    };
}
export interface FileUploadedMetadata {
    size: number;
    duration?: number;
}
export interface FileUploaded {
    filename: string;
    mimetype: string;
    processed: boolean;
    metadata?: FileUploadedMetadata;
}
export interface ApiDocumentationAccess {
    created_by: string;
    created_at: string;
    access_count: number;
    last_access: string|null;
    token: string;
}
export interface GeoJson {
    type: 'Point' | 'Polygon';
    coordinates: number[];
}
export interface WidgetLanguageReport {
    collection_position?: number;
    widget: TemplateWidget;
    property: TemplateWidgetProperty;
    language_iso_code: string;
}
export interface StatisticsAccess {
    token: string;
    created_at: string;
    created_by: string;
    access_count: number;
    last_access: null|string;
    customer_places: CustomerPlaceAccessManagement[];
}
export interface ClientAlert {
    uuid: string;
    platform: Platform|null;
    loan: boolean;
    description: string;
    created_at?: string;
    created_by?: string;
    customer_place_code?: string;
    status: PublishableStatusType;
    languages: ClientAlertLanguage[];
    actions: ClientAlertAction[];
    triggers: ClientAlertTrigger[];
    widget: Widget<WidgetObject>;
}
export interface ClientAlertLanguage {
    language_iso_code: string;
}
export interface ClientAlertAction {
    type: ClientAlertActionTypeEnum;
    props: {
      content?: Content;
      url?: string;
    };
}
export interface ClientAlertTrigger {
    type: ClientAlertTriggerTypeEnum;
    delay: number;
    props: {
      content?: Content;
    };
}
export interface TourLanguagesAvailable {
    language_iso_code: string;
}
export interface NotificationPush {
    created_by?: string;
    customer_place_code?: string;
    language_iso_code: string|null;
    title: string|null;
    body: string|null;
    created_at?: string;
    sent_at?: string|null;
}
