<ng-content select="[action]"></ng-content>
<ng-template #templateCategorySelectorModal let-modal>
    <app-view-wrapper-modal [title]="'selectCategory' | translate" (closeEmitter)="modal.close()">
        <div content class="p-3">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <!-- Use existing -->
                <li ngbNavItem="0">
                    <a ngbNavLink>
                        {{'useExisting' | translate}}
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-4">
                            <app-category-list (categorySelected)="selected($event)"></app-category-list>
                        </div>
                    </ng-template>
                </li>
                <!--Create -->
                <li ngbNavItem="1">
                    <a ngbNavLink>
                        {{'createNew' | translate}}
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-4">
                            <app-category-create (categoryCreated)="selected($event)"></app-category-create>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </app-view-wrapper-modal>
</ng-template>


