import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {GeoJson} from "@models/interfaces";
import {GeocodeHttpService} from "@services/http/geocode-http.service";
import {ToastService} from "@services/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-geolocation-input',
  templateUrl: './geolocation-input.component.html',
  styleUrls: ['./geolocation-input.component.scss']
})
export class GeolocationInputComponent implements OnInit {

  @Input() public geoJsonFormControl!: FormControl;
  @ViewChild('selectLocationModal') public modal!: ElementRef;

  public selectedMapLocation: string = '';
  public previewFormControl: FormControl = new FormControl('');
  public manuallyCoordinatesFormControl: FormControl = new FormControl('', [Validators.required, Validators.pattern("^(-?(?:90|[1-8]?\\d)(?:\\.\\d+)?),\\s*(-?(?:180|1[0-7]\\d|\\d{1,2})(?:\\.\\d+)?)$")]);
  private modalRef!: NgbModalRef;

  public constructor(
      private modalService: NgbModal,
      private geocodeHttpService: GeocodeHttpService,
      private toastService: ToastService,
      private translateService: TranslateService
  ) { }

  public openModal(): void {
    this.modalRef = this.modalService.open(this.modal, {size: 'lg', centered: true, animation: false, backdrop: 'static', keyboard: false});
  }

  public ngOnInit(): void {
    this.previewFormControl.disable();
    if (this.geoJsonFormControl.value !== null) {
      const geojson: GeoJson = this.geoJsonFormControl.value;
      this.selectedMapLocation = JSON.stringify(this.geoJsonFormControl.value);
      this.setPreviewLabel(geojson);
    }
  }

  public clear(): void {
    this.selectedMapLocation = '';
    this.geoJsonFormControl.setValue(null);
    this.manuallyCoordinatesFormControl.setValue(null);
    this.previewFormControl.setValue(null);
  }

  public confirm(): void {
    if (this.manuallyCoordinatesFormControl.invalid) {
      return;
    }
    const coordinates: string[] = this.manuallyCoordinatesFormControl.value.split(',');
    const geojson: GeoJson = {
      type: 'Point',
      coordinates: [
          parseFloat(coordinates[1].trim()),
          parseFloat(coordinates[0].trim())
      ]
    };
    this.selectedMapLocation = JSON.stringify(geojson);
    this.geoJsonFormControl.setValue(geojson);
    this.setPreviewLabel(geojson);
    this.modalRef.close();
  }

  public mapLocationChanged(geojsonEncoded: string): void {
    this.selectedMapLocation = geojsonEncoded;
    const geojson: GeoJson = JSON.parse(geojsonEncoded);
    this.geoJsonFormControl.setValue(geojson);
    this.setPreviewLabel(geojson);
    this.modalRef.close();
  }

  private setPreviewLabel(geojson: GeoJson): void {
    this.geocodeHttpService.reverse(geojson.coordinates[1], geojson.coordinates[0]).subscribe({
      next: (label: string) => {
        this.previewFormControl.setValue(label);
      },
      error: () => {
        this.translateService.stream('errorOnGetGeoLocation').subscribe((value: string) => {
          this.toastService.show({textOrTemplate: value, classname: 'toast bg-danger text-white small'});
        });
      }
    });
  }
}
