import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie";
import {Observable, of, Subject, switchMap} from "rxjs";
import {CustomerPlace, User} from "@models/interfaces";
import {UserHttpService} from "@services/http/user-http.service";

type UserManagerSettings = {
  customerPlaceCodeSelected: string|null;
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private manager: UserManagerSettings;
  public onCustomerPlaceSelection: Subject<void> = new Subject<void>();
  private permissions: string[]|undefined;

  public constructor(
      private cookieService: CookieService,
      private userHttpService: UserHttpService
  ) {
    this.manager = {
      customerPlaceCodeSelected: this.cookieService.get('current_customer_place_code') || null,
    };
  }

  public setCustomerPlace(customerPlace: CustomerPlace): void {
    this.cookieService.put('current_customer_place_code', customerPlace.code);
    this.manager.customerPlaceCodeSelected = customerPlace.code;
    this.onCustomerPlaceSelection.next();
  }

  public getCustomerPlaceSelected(): string|null {
    return this.manager.customerPlaceCodeSelected;
  }

  public clear(): void {
    this.manager.customerPlaceCodeSelected = null;
    this.permissions = undefined;
    this.cookieService.remove('current_customer_place_code');
    localStorage.removeItem('dont_remember_confirmation');
  }

  public addRememberNotConfirmation(identifier: string): void {
    let identifiers: string[] = [];
    const item: string|null = localStorage.getItem('dont_remember_confirmation');
    if (item !== null) {
      identifiers = JSON.parse(item);
    }
    if (!identifiers.includes(identifier)) {
      identifiers.push(identifier)
    }
    localStorage.setItem('dont_remember_confirmation', JSON.stringify(identifiers));
  }

  public isRememberNotConfirmation(identifier: string): boolean {
    const item: string|null = localStorage.getItem('dont_remember_confirmation');
    if (item !== null) {
      let identifiers = JSON.parse(item);

      return identifiers.includes(identifier);
    }
    return false;
  }

  public getPermissions(): Observable<string[]> {
    if (this.permissions === undefined) {
      return this.userHttpService.current().pipe(
          switchMap(
              (response: User) => {
                this.permissions = [];
                response.roles.forEach(role => {
                  role.permissions.forEach(permission => {
                    if (!this.permissions?.includes(permission)) {
                      this.permissions?.push(permission);
                    }
                  });
                });
                return of(this.permissions);
              }
          )
      );
    }
    return of(this.permissions as string[]);
  }
}
