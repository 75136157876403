<div class="row bg-white justify-content-center w-100 min-vh-100 px-3">
    <div class="pt-xl-3 pt-lg-3 pt-md-4 pt-sm-5 pt-xs-5 col-12">
        <div class="px-md-3 px-sm-3 px-xs-3" *ngIf="!loader; else spinner">
            <div class="border-success border-bottom pb-2 mb-2">
                <h5 class="h5 text-success my-2">
                    {{title}}
                </h5>
            </div>
            <div>
                <ng-content select="[view]"></ng-content>
            </div>
        </div>
        <ng-template #spinner>
            <app-spinner></app-spinner>
        </ng-template>
    </div>
</div>
