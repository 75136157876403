import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlingService} from "@services/error-handling.service";

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements AfterViewInit {

  @ViewChild('swagger') swaggerDom!: ElementRef<HTMLDivElement>;
  public loader: boolean = true;
  public apiDoc: any;

  public constructor(
      private route: ActivatedRoute,
      private httpClient: HttpClient,
      @Inject('VENTOUR_REST_SERVICE_HOST') protected host: string,
      private errorHandlingService: ErrorHandlingService
  ) { }

  public ngAfterViewInit(): void {
    this.httpClient.get(this.host + '/documentation/' + this.route.snapshot.params['token']).subscribe({
      next: (response: any) => {
        this.apiDoc = response;
        this.loader = false;

        SwaggerUIBundle({
          dom_id: '#' + this.swaggerDom.nativeElement.id,
          layout: 'BaseLayout',
          presets: [
            SwaggerUIBundle.presets.apis,
            SwaggerUIBundle.SwaggerUIStandalonePreset
          ],
          spec: response,
          docExpansion: 'full',
          operationsSorter: 'alpha'
        });
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error)
    });
  }
}
