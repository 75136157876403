import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationBarComponent} from "./navigation-bar/navigation-bar.component";
import {ViewWrapperComponent} from './view-wrapper/view-wrapper.component';
import {TranslateModule} from "@ngx-translate/core";
import {ViewWrapperModalComponent} from './view-wrapper-modal/view-wrapper-modal.component';
import {ConfirmationMessageComponent} from './confirmation-message/confirmation-message.component';
import {SelectLanguageSelectorComponent} from "./select-language-selector/select-language-selector.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StatusSelectorComponent} from "./status-selector/status-selector.component";
import {SmallButtonComponent} from "./small-button/small-button.component";
import {allIcons, NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {
    NgbCollapseModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbToastModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import {LanguagesSelectorComponent} from "./languages-selector/languages-selector.component";
import { MediumButtonComponent } from './medium-button/medium-button.component';
import { LanguageFlagComponent } from './language-flag/language-flag.component';
import { NavigationBarUserActionsComponent } from './navigation-bar-user-actions/navigation-bar-user-actions.component';
import { PaginationComponent } from './pagination/pagination.component';
import {CustomerPlaceSelectorComponent} from "./customer-place-selector/customer-place-selector.component";
import {PipesModule} from "@pipes/pipes.module";
import { SpinnerComponent } from './spinner/spinner.component';
import { ContentSelectorComponent } from './content-selector/content-selector.component';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { HelpInfoHoverComponent } from './help-info-hover/help-info-hover.component';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryCreateComponent } from './category-create/category-create.component';
import { SearchCollectionComponent } from './search-collection/search-collection.component';
import { JsonEditorComponent } from './json-editor/json-editor.component';
import { DropdownContentComponent } from './dropdown-content/dropdown-content.component';
import {RouterModule} from "@angular/router";
import { GpsLocationSelectorComponent } from './gps-location-selector/gps-location-selector.component';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import { GeocodeReverseLabelComponent } from './geocode-reverse-label/geocode-reverse-label.component';
import { GeolocationInputComponent } from './geolocation-input/geolocation-input.component';
import { ListColumnOrderComponent } from './list-column-order/list-column-order.component';
import { OpenNewTabActionComponent } from './open-new-tab-action/open-new-tab-action.component';
import { NgxJsonViewerModule} from "ngx-json-viewer";


@NgModule({
    declarations: [
        NavigationBarComponent,
        ViewWrapperComponent,
        ViewWrapperModalComponent,
        ConfirmationMessageComponent,
        SelectLanguageSelectorComponent,
        StatusSelectorComponent,
        SmallButtonComponent,
        LanguagesSelectorComponent,
        MediumButtonComponent,
        LanguageFlagComponent,
        NavigationBarUserActionsComponent,
        PaginationComponent,
        CustomerPlaceSelectorComponent,
        SpinnerComponent,
        ContentSelectorComponent,
        ToastContainerComponent,
        HelpInfoHoverComponent,
        CategorySelectorComponent,
        CategoryListComponent,
        CategoryCreateComponent,
        SearchCollectionComponent,
        JsonEditorComponent,
        DropdownContentComponent,
        GpsLocationSelectorComponent,
        GeocodeReverseLabelComponent,
        GeolocationInputComponent,
        ListColumnOrderComponent,
        OpenNewTabActionComponent
    ],
    exports: [
        NavigationBarComponent,
        ViewWrapperComponent,
        ViewWrapperModalComponent,
        ConfirmationMessageComponent,
        SelectLanguageSelectorComponent,
        StatusSelectorComponent,
        SmallButtonComponent,
        LanguagesSelectorComponent,
        MediumButtonComponent,
        LanguageFlagComponent,
        PaginationComponent,
        CustomerPlaceSelectorComponent,
        SpinnerComponent,
        ContentSelectorComponent,
        ToastContainerComponent,
        HelpInfoHoverComponent,
        CategorySelectorComponent,
        SearchCollectionComponent,
        JsonEditorComponent,
        DropdownContentComponent,
        GpsLocationSelectorComponent,
        GeocodeReverseLabelComponent,
        GeolocationInputComponent,
        ListColumnOrderComponent,
        OpenNewTabActionComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        NgxBootstrapIconsModule.pick(allIcons),
        NgbTooltipModule,
        NgbPaginationModule,
        PipesModule,
        NgbToastModule,
        NgbNavModule,
        NgbCollapseModule,
        RouterModule,
        LeafletModule,
        FormsModule,
        NgxJsonViewerModule
    ]
})
export class CommonComponentsModule {
}
