import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly storageKey: string = 'user_auth';

  public start(token: string): void {
    localStorage.setItem(this.storageKey, token);
  }

  public getToken(): string|null {
    return localStorage.getItem(this.storageKey);
  }

  public isLogged(): boolean {
    return localStorage.getItem(this.storageKey) !== null;
  }

  public clear(): void {
    localStorage.removeItem(this.storageKey);
  }
}
