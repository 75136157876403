import {Injectable} from "@angular/core";
import {BaseHttpService} from "@services/http/base-http.service";
import {Observable} from "rxjs";
import {HttpParams} from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class GeocodeHttpService extends BaseHttpService {

    public reverse(latitude: number, longitude: number): Observable<string> {

        const params: HttpParams = new HttpParams()
            .set('latitude', latitude)
            .set('longitude', longitude);

        return this.httpClient.get<string>(
            this.host + '/geocode/reverse', {headers: this.getBasicRequestHeaders(), params}
        );
    }
}
