import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textShortener'
})
export class TextShortenerPipe implements PipeTransform {

  public transform(value: string, length: number): string {
    if (value.length > length) {
      return value.slice(0, length) + '...';
    }
    return value;
  }
}
