import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {Role, User} from "src/app/model/interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserHttpService extends BaseHttpService {

  public current(): Observable<User> {
    return this.httpClient.get<User>(
      this.host + '/users/current',
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public create(user: User): Observable<void> {
    return this.httpClient.post<void>(
        this.host + '/users/create',
        user,
        {headers: this.getBasicRequestHeaders()}
    );
  }
  
  public list(): Observable<User[]> {
    return this.httpClient.get<User[]>(
      this.host + '/users/list',
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public details(username: string): Observable<User> {
    return this.httpClient.get<User>(
      this.host + '/users/details/' + username,
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public edit(user: User): Observable<void> {
    return this.httpClient.put<void>(
      this.host + '/users/edit',
          user,
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public roles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(
        this.host + '/users/roles',
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<void> {
    return this.httpClient.put<void>(
        this.host + '/configuration/change-password',
        {
          old_password: oldPassword,
          new_password: newPassword
        },
        {headers: this.getBasicRequestHeaders()}
    );
  }
}
