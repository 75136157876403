import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginHttpService} from "@services/http/login-http.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ErrorHandlingService} from "@services/error-handling.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public formLogin: FormGroup;
  public loginValidationErrorMessage: string = '';
  public loginServerError: boolean = false;
  public submitted: boolean = false;
  public loader: boolean = false;

  public constructor(
    @Inject('VERSION') public version: string,
    private loginHttpService: LoginHttpService,
    private translateService: TranslateService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService,
    private titleService: Title
  ) {
    this.formLogin = new FormGroup({
      'username': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  public ngOnInit(): void {
    this.translateService.stream('errorLoginValidation').subscribe((value: string) => {
      this.loginValidationErrorMessage = value;
    });
    this.translateService.stream('login').subscribe((value: string) => {
      this.titleService.setTitle(value);
    });
  }

  public submit(): void {
    this.loginServerError = false;
    this.submitted = true;
    if (this.formLogin.invalid) {
      return;
    }
    this.loader = true;
    this.loginHttpService.auth(
      this.formLogin.get('username')?.value,
      this.formLogin.get('password')?.value
    ).subscribe({
      next: () => {
        this.router.navigate(['/manager']);
      },
      error: (error: HttpErrorResponse) => {
        if ([401, 400, 404].includes(error.status)) {
          this.loginServerError = true;
          this.submitted = false;
          this.loader = false;
        } else {
          this.errorHandlingService.handleHttp(error);
        }
      }
    });
  }

  public keydownHandler(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.submit();
    }
  }
}
