import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerPlaceHttpService} from "@services/http/customer-place-http.service";
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {FormControl, FormGroup} from "@angular/forms";
import {ErrorHandlingService} from "@services/error-handling.service";
import {CustomerPlace} from "@models/interfaces";

@Component({
  selector: 'app-languages-selector',
  templateUrl: './languages-selector.component.html',
  styleUrls: ['./languages-selector.component.scss']
})
export class LanguagesSelectorComponent implements OnInit {

  @Output() public languageSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() public setDefaultAll: boolean = false;
  @Input() public currentLanguages!: string[];

  private loader: boolean = false;
  public form!: FormGroup;
  public customerPlaceLanguages: string[] = [];

  public constructor(
    private customerPlaceHttpService: CustomerPlaceHttpService,
    private route: ActivatedRoute,
    private errorHandlingService: ErrorHandlingService
  ) { }

  public ngOnInit(): void {

    this.form = new FormGroup({});

    this.loader = true;
    this.customerPlaceHttpService.details(this.route.parent?.snapshot.params['customerPlaceCode']).subscribe({
      next: (response: CustomerPlace) => {
        for (const customerPlaceLanguage of response.languages) {
          this.form.addControl(
            customerPlaceLanguage.iso_code,
            new FormControl(this.currentLanguages?.includes(customerPlaceLanguage.iso_code) || this.setDefaultAll)
          );
          this.customerPlaceLanguages.push(customerPlaceLanguage.iso_code);
        }
        if (this.setDefaultAll) {
          this.currentLanguages = this.customerPlaceLanguages;
          this.languageSelected.emit(this.currentLanguages);
        }
        this.loader = false;
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error),
      complete: () => {
        this.form.valueChanges.subscribe((values: {[code: string]: boolean}) => {
          const codes = [];
          for (const key in values) {
            if (values[key]) {
              codes.push(key);
            }
          }
          this.currentLanguages = codes;
          this.languageSelected.emit(this.currentLanguages);
        });
      }
    });
  }
}
