<div class="row collapse-trigger text-success border-bottom border-light" (click)="isCollapsed=!isCollapsed">
    <div class="col p-2 small fw-bold">
        {{label}}
    </div>
    <div class="col p-2 d-flex justify-content-end">
        <i-bs [name]="'caret-down-fill'" width="18" height="18" *ngIf="isCollapsed; else caretUp"></i-bs>
        <ng-template #caretUp>
            <i-bs [name]="'caret-up-fill'" width="18" height="18"></i-bs>
        </ng-template>
    </div>
</div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="border-end border-start border-bottom border-light p-3">
    <ng-content select="[content]"></ng-content>
</div>
