<div>
  <form [formGroup]="form">
    <div class="form-check" *ngFor="let code of customerPlaceLanguages; let i = index">
      <input class="form-check-input" type="checkbox" [formControlName]="code" id="language-{{i}}">
      <label class="form-check-label small" for="language-{{i}}">
        {{'languages.' + code | translate}}
      </label>
    </div>
  </form>
</div>
