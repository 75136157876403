import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeRawFormatter'
})
export class FileSizeRawFormatterPipe implements PipeTransform {

  public transform(bytes: number): string {
    switch (true) {
      case bytes > 11073741824:
        return Math.floor(bytes / 1024**3).toString() + ' GB';
      case bytes > 1048576:
        return Math.floor(bytes / 1024**2).toString() + ' MB';
      default:
        return Math.floor(bytes / 1024).toString() + ' KB';
    }
  }
}
