import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss']
})
export class DropdownContentComponent {
  @Input() public label: string = '';
  public isCollapsed: boolean = true;
}
