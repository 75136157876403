import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Category} from "@models/interfaces";
import CategoryHttpService from "@services/http/category-http.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "@services/toast.service";
import {ErrorHandlingService} from "@services/error-handling.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss']
})
export class CategoryCreateComponent implements OnInit {

  @Output() public categoryCreated: EventEmitter<Category> = new EventEmitter<Category>();
  public loader: boolean = false;
  public form!: FormGroup;
  public submitted: boolean = false;

  public constructor(
      private categoryHttpService: CategoryHttpService,
      private toastService: ToastService,
      private errorHandlingService: ErrorHandlingService,
      private translateService: TranslateService,
      private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'description': new FormControl('', [Validators.required, Validators.maxLength(128)]),
      'default': new FormControl(false)
    });
  }

  public submit(): void {
    if (this.form.invalid) {
      return;
    }
    const request: Category = {
      code: '',
      description: this.form.get('description')?.value,
      default: this.form.get('default')?.value
    };
    this.loader = true;
    this.categoryHttpService.create(request).subscribe({
      next: (response: Category) => {
        this.categoryCreated.emit(response);
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.translateService.stream('categoryAlreadyExists', {name: request.description}).subscribe((value: string) => {
            this.toastService.show({textOrTemplate: value, classname: 'toast bg-danger text-white small'});
          });
          this.loader = false;
        } else {
          this.errorHandlingService.handleHttp(error);
        }
      }
    });
  }
}
