import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {CustomerPlaceLanguage} from "@models/interfaces";
import {UserSettingsService} from "@services/user-settings.service";
import {CustomerPlaceHttpService} from "@services/http/customer-place-http.service";
import {ErrorHandlingService} from "@services/error-handling.service";
import {CustomerPlace} from "@models/interfaces";

@Component({
  selector: 'app-select-language-selector',
  templateUrl: './select-language-selector.component.html',
  styleUrls: ['./select-language-selector.component.scss']
})
export class SelectLanguageSelectorComponent implements OnInit {

  @Output() public languageSelected: EventEmitter<string> = new EventEmitter<string>();
  @Input() public field!: string;

  public loader: boolean = false;
  public formControlLanguage: FormControl = new FormControl('');
  public languages: CustomerPlaceLanguage[] = [];

  public constructor(
      private userSettingsService: UserSettingsService,
      private customerPlaceHttpService: CustomerPlaceHttpService,
      private errorHandlingService: ErrorHandlingService
  ) { }

  public ngOnInit() {
    this.loader = true;
    this.customerPlaceHttpService.details(this.userSettingsService.getCustomerPlaceSelected() as string).subscribe({
      next: (response: CustomerPlace) => {
        this.languages = response.languages;
        this.formControlLanguage.valueChanges.subscribe((value: CustomerPlaceLanguage) => {
          this.languageSelected.emit(value.iso_code);
        });
        if (this.languages.length) {
          this.formControlLanguage.setValue(this.languages[0]);
        }
        this.loader = false;
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error)
    });
  }
}
