import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from "@services/session.service";
import {environment} from "@env/environment";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {UserSettingsService} from "@services/user-settings.service";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {CookieModule} from "ngx-cookie";
import {CommonComponentsModule} from "@commonComponents/common-components.module";
import {NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { ErrorComponent } from './error/error.component';
import packageJson from '../../package.json';
import { DocumentationComponent } from './documentation/documentation.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {CacheInterceptor} from "./interceptors/cache.interceptor";
import {ErrorInterceptor} from "./interceptors/error.interceptor";

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ErrorComponent,
        DocumentationComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'es-ES'
        }),
        ReactiveFormsModule,
        CookieModule.withOptions(),
        CommonComponentsModule,
        NgxBootstrapIconsModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        SessionService,
        UserSettingsService,
        {provide: 'VENTOUR_REST_SERVICE_HOST', useValue: environment.ventourRestService.host},
        {provide: 'FULL_PREVIEW_HOST', useValue: environment.fullPreviewHost},
        {provide: 'PREVIEW_COMMUNICATION_HOST', useValue: environment.previewCommunication.host},
        {provide: 'PREVIEW_COMMUNICATION_TOKEN', useValue: environment.previewCommunication.jwt},
        {provide: 'MEDIA_SOURCE_CDN_URL', useValue: environment.ventourCdnUrl},
        {provide: 'APP_HOST', useValue: environment.appHost},
        {provide: 'VERSION', useValue: packageJson.version},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
