import {Injectable} from "@angular/core";
import {BaseHttpService} from "@services/http/base-http.service";
import {Category} from "@models/interfaces";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export default class CategoryHttpService extends BaseHttpService {

    public create(category: Category): Observable<Category> {
        return this.httpClient.post<Category>(
            this.host + '/category/create',
            category,
            {headers: this.getBasicRequestHeaders()}
        );
    }

    public list(): Observable<Category[]> {
        return this.httpClient.get<Category[]>(
            this.host + '/category/list',
            {headers: this.getBasicRequestHeaders()}
        );
    }

    public delete(code: string): Observable<void> {
        return this.httpClient.delete<void>(
            this.host + '/category/delete/' + code,
            {headers: this.getBasicRequestHeaders()}
        );
    }
}
