import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SessionService} from "../session.service";

/**
 * Based on Laravel default pagination
 */
export interface PaginatedResponse<Entity> {
  current_page: number;
  data: Entity[];
  last_page: number;
  per_page: number;
  total: number;
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseHttpService {

  public fromCache: boolean = true;

  protected constructor(
    protected httpClient: HttpClient,
    @Inject('VENTOUR_REST_SERVICE_HOST') protected host: string,
    protected sessionService: SessionService
  ) {
  }

  protected getBasicRequestHeaders(): HttpHeaders {

    const fromCache: string = this.fromCache ? 'public' : 'no-store';

    this.fromCache = true;

    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Cache-Control', fromCache)
      .set('Authorization', 'Bearer ' + this.sessionService.getToken());
  }

  public useCache(value: boolean): this {
    this.fromCache = value;

    return this;
  }
}
