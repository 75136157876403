import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss']
})
export class JsonEditorComponent implements OnInit {

  @Output() public jsonChanged: EventEmitter<string> = new EventEmitter();
  @ViewChild('editor') editor!: ElementRef<HTMLElement>;
  @Input() public content!: string;
  @Input() public disabled: boolean = false;

  public data: object | undefined;
  public options: any = {
    maxLines: 2000,
    printMargin: false,
    tabSize: 2,
    useWorker: false
  };

  public ngOnInit(): void {

    if (this.content) {
      this.data = JSON.parse(this.content)
    }
  }

  public change(value: unknown): void {
    this.jsonChanged.emit(value as string);
  }
}
