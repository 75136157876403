<div>
    <div class="my-3 alert alert-danger small" *ngIf="invalidGeojsonFormatError">
        {{'invalidGeojsonFormat' | translate}}
    </div>
    <div class="my-3 p-2">
        <div class="form-group">
            <input class="form-check-input" type="checkbox" id="set_manually" [(ngModel)]="setManually">
            <label class="form-label text-secondary small mx-2" for="set_manually">
                {{ 'selectManually' | translate }}
            </label>
        </div>
    </div>
    <div class="my-3 p-2" *ngIf="setManually; else mapContainer">
        <form [formGroup]="form">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="latitude" class="form-label text-secondary small">
                            {{'latitude' | translate}}
                        </label>
                        <input type="text" class="form-control form-control-sm" [ngClass]="{'is-invalid': form.get('latitude')?.invalid&&!form.pristine}" id="latitude" formControlName="latitude" />
                        <div class="invalid-feedback small">
                            <p *ngIf="form.get('latitude')?.hasError('required')">
                                {{'mandatoryValue' | translate}}
                            </p>
                            <p *ngIf="form.get('latitude')?.hasError('max')||form.get('latitude')?.hasError('min')">
                                {{'latitudeRangeErrorMessage' | translate}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="longitude" class="form-label text-secondary small">
                            {{'longitude' | translate}}
                        </label>
                        <input type="text" class="form-control form-control-sm" [ngClass]="{'is-invalid': form.get('longitude')?.invalid&&!form.pristine}" id="longitude" formControlName="longitude" />
                        <div class="invalid-feedback small">
                            <p *ngIf="form.get('longitude')?.hasError('required')">
                                {{'mandatoryValue' | translate}}
                            </p>
                            <p *ngIf="form.get('longitude')?.hasError('max')||form.get('longitude')?.hasError('min')">
                                {{'longitudeRangeErrorMessage' | translate}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ng-template #mapContainer>
        <div class="my-3 py-3 small text-secondary" *ngIf="!currentPosition">
            {{'selectAnyPosition' | translate}}
        </div>
        <div #map leaflet class="map-wrapper"
             [leafletOptions]="options"
             (leafletMapReady)="onMapReady($event)"
             (leafletClick)="mapSelect($event)">
            <div [leafletLayer]="marker" *ngIf="marker"></div>
        </div>
    </ng-template>
</div>
