<ng-content select="[action]"></ng-content>
<ng-template #confirmationModal let-modal>
    <app-view-wrapper-modal [title]="'actionNeedsConfirmation' | translate" (closeEmitter)="modal.close()">
        <div class="p-4" content>
            <div class="my-3">
                <p>
                    {{message}}
                </p>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [formControl]="rememberNotConfirmationFormControl" id="remember_not_confirmation" />
                    <label for="remember_not_confirmation" class="form-check-label small">
                        {{'rememberNotConfirmation' | translate}}
                    </label>
                </div>
            </div>
            <div class="text-center">
                <app-medium-button [text]="'confirm' | translate" [icon]="'check-circle'" (trigger)="confirm()"></app-medium-button>
            </div>
        </div>
    </app-view-wrapper-modal>
</ng-template>
