import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {Client, CustomerPlace} from "@models/interfaces";

@Injectable({
  providedIn: 'root'
})
export class CustomerPlaceHttpService extends BaseHttpService {

  public details(code: string): Observable<CustomerPlace> {
    return this.httpClient.get<CustomerPlace>(
      this.host + '/customer-place/details/' + code,
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public clients(code: string): Observable<Client[]> {
    return this.httpClient.get<Client[]>(
        this.host + '/customer-place/clients/' + code,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public list(): Observable<CustomerPlace[]> {
    return this.httpClient.get<CustomerPlace[]>(
      this.host + '/customer-place/list',
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public types(): Observable<string[]> {
    return this.httpClient.get<string[]>(
        this.host + '/customer-place/types',
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public create(customerPlace: CustomerPlace): Observable<void> {
    return this.httpClient.post<void>(
        this.host + '/customer-place/create',
        customerPlace,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public edit(customerPlace: CustomerPlace): Observable<void> {
    return this.httpClient.put<void>(
        this.host + '/customer-place/edit',
        customerPlace,
        {headers: this.getBasicRequestHeaders()}
    );
  }
}
