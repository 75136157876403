<div class="input-group mb-3">
    <input type="text" [formControl]="previewFormControl" class="form-control form-control-sm" aria-label="GPS location" aria-describedby="CustomerPlace GPS location">
    <div class="input-group-append">
        <button class="btn btn-sm btn-success text-white" type="button" (click)="openModal()" *ngIf="!geoJsonFormControl.value; else clearAction">
            <i-bs [name]="'geo-alt'" width="12" height="12"></i-bs>
            {{'select' | translate}}
        </button>
        <ng-template #clearAction>
            <button class="btn btn-sm btn-success text-white" (click)="clear()" type="button">
                <i-bs [name]="'trash'" width="12" height="12"></i-bs>
                {{'delete' | translate}}
            </button>
        </ng-template>
    </div>
</div>
<ng-template #selectLocationModal let-modal>
    <app-view-wrapper-modal [title]="'geolocation' | translate" (closeEmitter)="modal.close()">
        <div content class="p-3">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <!-- Manually -->
                <li ngbNavItem="0">
                    <a ngbNavLink>
                        {{'addManually' | translate}}
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-4">
                            <div class="my-3">
                                <label for="coordinates" class="form-label text-secondary required small">
                                    {{'coordinates' | translate}}
                                </label>
                                <input type="text" class="form-control form-control-sm" [ngClass]="{'is-invalid': manuallyCoordinatesFormControl.invalid}" id="coordinates" [formControl]="manuallyCoordinatesFormControl" />
                                <div class="invalid-feedback small">
                                    <p *ngIf="manuallyCoordinatesFormControl.hasError('required')">
                                        {{'mandatoryValue' | translate}}
                                    </p>
                                    <p *ngIf="manuallyCoordinatesFormControl.hasError('pattern')">
                                        {{'coordinatesFormatError' | translate}}
                                    </p>
                                </div>
                            </div>
                            <div class="my-3 d-flex justify-content-end">
                                <app-medium-button [text]="'save' | translate" [icon]="'save'" (trigger)="confirm()"></app-medium-button>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <!-- Select on map -->
                <li ngbNavItem="1">
                    <a ngbNavLink>
                        {{'selectOnMap' | translate}}
                    </a>
                    <ng-template ngbNavContent>
                        <div class="p-4">
                            <app-gps-location-selector [geojson]="selectedMapLocation" (locationChanged)="mapLocationChanged($event)"></app-gps-location-selector>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </app-view-wrapper-modal>
</ng-template>
