import { Component, OnInit } from '@angular/core';
import {UserHttpService} from "@services/http/user-http.service";
import {HttpErrorResponse} from "@angular/common/http";
import {SessionService} from "@services/session.service";
import {UserSettingsService} from "@services/user-settings.service";
import {ErrorHandlingService} from "@services/error-handling.service";
import {User} from "@models/interfaces";

@Component({
  selector: 'app-navigation-bar-user-actions',
  templateUrl: './navigation-bar-user-actions.component.html',
  styleUrls: ['./navigation-bar-user-actions.component.scss']
})
export class NavigationBarUserActionsComponent implements OnInit {

  public loader: boolean = false;
  public currentUser!: User;

  public constructor(
      private userHttpService: UserHttpService,
      private sessionService: SessionService,
      private userSettingsService: UserSettingsService,
      private errorHandlingService: ErrorHandlingService
  ) { }

  public ngOnInit(): void {
    this.loader = true;
    this.userHttpService.current().subscribe({
      next: (response: User) => {
        this.currentUser = response;
        this.loader = false;
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error)
    });
  }

  public logout(): void {
    this.userSettingsService.clear();
    this.sessionService.clear();
    /**
     * Reload tab in browser for clear HTTP interceptor
     */
    window.location.reload();
  }
}
