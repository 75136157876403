import {Component, HostListener, Input} from '@angular/core';
import CtrlKeyDownCheckerService from "@services/ctrl-key-down-checker.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-open-new-tab-action',
  templateUrl: './open-new-tab-action.component.html',
  styleUrls: ['./open-new-tab-action.component.scss']
})
export class OpenNewTabActionComponent {

  @Input() public navigateToCommands!: any[];

  public constructor(
      private ctrlKeyDownCheckerService: CtrlKeyDownCheckerService,
      private router: Router
  ) { }

  @HostListener('click', ['$event.target'])
  public action(): void {
    if (this.ctrlKeyDownCheckerService.ctrlIsDown) {
      const url = this.router.serializeUrl(this.router.createUrlTree(this.navigateToCommands));
      window.open(url, '_blank');
    } else {
      this.router.navigate(this.navigateToCommands);
    }
  }
}
