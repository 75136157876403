import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export default class CtrlKeyDownCheckerService {

    public ctrlIsDown: boolean = false;

    public constructor() {
        window.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.keyCode === 17) {
                this.ctrlIsDown = true;
            }
        });
        window.addEventListener('keyup', (event: KeyboardEvent) => {
            if (event.keyCode === 17) {
                this.ctrlIsDown = false;
            }
        });
    }
}
