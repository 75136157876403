<div class="form-group">
    <label for="languageSelected" class="form-label text-secondary small">
        {{field}}
    </label>
    <select id="languageSelected" class="form-select form-select-sm" [formControl]="formControlLanguage">
        <option [ngValue]="language" *ngFor="let language of languages">
            {{'languages.' + language.iso_code | translate}}
        </option>
    </select>
</div>
