<div *ngIf="!loader; else spinner">
    <table class="table table-striped small">
        <thead class="text-success">
            <tr>
                <th scope="col">{{'description' | translate}}</th>
                <th scope="col">{{'code' | translate}}</th>
                <th scope="col">{{'createdAt' | translate}}</th>
                <th scope="col">{{'createdBy' | translate}}</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-category [ngForOf]="categories">
            <tr>
                <td>
                    {{category.description}}
                </td>
                <td>
                    {{category.code}}
                </td>
                <td>
                    {{category.created_at | dateUtcToLocalRawFormat}}
                </td>
                <td>
                    {{category.created_by}}
                </td>
                <td>
                    <div class="d-flex justify-content-end">
                        <app-medium-button [text]="'select' | translate" (trigger)="categorySelected.emit(category)"></app-medium-button>
                    </div>
                </td>
            </tr>
        </ng-template>
        </tbody>
    </table>
</div>
<ng-template #spinner>
    <app-spinner></app-spinner>
</ng-template>
