<div class="collapse navbar-collapse">
    <ul class="navbar-nav">
        <li class="nav-item" *ngIf="customerPlaceCurrentCode">
            <a class="nav-link fw-semibold text-white cursor-pointer small" *ngIf="customerPlaceCurrentName" (click)="customerPlaceSelection()" [title]="'goToCustomerPlaceSelection' | translate">
                {{customerPlaceCurrentName}}
            </a>
        </li>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'boxes',
            title: 'managementContents' | translate,
            tooltip: 'contents' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/content/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'map',
            title: 'managementMaps' | translate,
            tooltip: 'maps' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/map/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'diagram-3',
            title: 'managementTours' | translate,
            tooltip: 'tours' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/tour/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'cloud-haze2',
            title: 'managementPublishes' | translate,
            tooltip: 'publish' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/publish/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'translate',
            title: 'managementTranslationFiles' | translate,
            tooltip: 'translationsFiles' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/translations-files/clients-list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'megaphone',
            title: 'managementClientAlerts' | translate,
            tooltip: 'clientAlerts' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/client-alerts/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'bell',
            title: 'notificationsPush' | translate,
            tooltip: 'notificationsPush' | translate,
            link: '/manager/customer-place/' + customerPlaceCurrentCode + '/notification-push/list',
            show: customerPlaceCurrentCode !== null
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'key',
            title: 'adminArea' | translate,
            tooltip: 'adminArea' | translate,
            link: '/admin/users/list',
            show: isAdmin
        }"></ng-container>
    </ul>
</div>
<ng-template #listElement let-icon="icon" let-title="title" let-tooltip="tooltip" let-link="link" let-show="show">
    <li class="nav-item small" *ngIf="show">
        <a class="nav-link text-white mx-2"
           [routerLink]="link"
           [ngbTooltip]="tooltip"
           [title]="title"
           placement="bottom"
           tooltipClass="tooltip-custom">
            <i-bs [name]="icon" width="22" height="22"></i-bs>
        </a>
    </li>
</ng-template>
