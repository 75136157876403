export const environment = {
  production: false,
  appHost: 'https://staging.cms.ventour.gvam.com',
  ventourRestService: {
    host: 'https://staging.api.ventour.gvam.com/api/v1/cms'
  },
  ventourCdnUrl: 'https://s3.eu-west-2.amazonaws.com/ventour-cdn.gvam-staging',
  tts: {
    host: 'https://tts.gvam.es',
    clientId: '2o2moy4ju2gwgw0owcwo0k4o488k8kkws8wg40000gcw004o0s',
    secret: 'eyl35kggbcowck84wkkskgwc80o0oscks8gc044gkos0oswsk'
  },
  fullPreviewHost: 'https://staging.preview.ventour.gvam.com',
  previewCommunication: {
    host: 'https://staging.preview.communication-service.gvam.com',
    jwt: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZW50b3VyLXdlYi1jbGllbnQiLCJpc3MiOiJndmFtIiwiZXhwIjoiMjAyNy0wNi0wMVQwNzoyMzoyNi45MzlaIn0.mCurTVGM3AXyjhDqHFdq0TXKy7F6SihuXCXeKVswI_Y'
  },
  statisticsPanelHost: "https://staging.panel.gstats.es"
};
