<ng-content select="[action]"></ng-content>
<ng-template #contentSelectionModal let-modal>
    <app-view-wrapper-modal [loader]="loader" [title]="'selectContent' | translate" (closeEmitter)="modal.close()">
        <div class="p-4" content>
            <div>
                <div *ngIf="!loader">
                    <app-search-collection [handler]="searchHandler" [collection]="contents" (collectionSearched)="contents=$event"></app-search-collection>
                </div>
                <table class="table table-striped">
                    <tbody>
                    <tr class="small" *ngFor="let content of contents">
                        <td>
                            {{content.description}}
                        </td>
                        <td>
                            {{'' + content.status | translate}}
                        </td>
                        <td>
                            <div class="col text-end">
                                <app-medium-button [text]="'select' | translate" (trigger)="contentSelected.emit(content); modal.close()"></app-medium-button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </app-view-wrapper-modal>
</ng-template>
