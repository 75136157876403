import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {FormControl} from "@angular/forms";
import {UserSettingsService} from "@services/user-settings.service";

@Component({
  selector: 'app-confirmation-message',
  templateUrl: './confirmation-message.component.html',
  styleUrls: ['./confirmation-message.component.scss']
})
export class ConfirmationMessageComponent {

  @ViewChild('confirmationModal') public modal!: ElementRef;
  @Output() public confirmedEmitter: EventEmitter<void> = new EventEmitter();
  @Input() public message!: string;
  @Input() public rememberId!: string;
  private modalRef!: NgbModalRef;
  public rememberNotConfirmationFormControl: FormControl = new FormControl(false);

  public constructor(
      private modalService: NgbModal,
      private userSettingsService: UserSettingsService
  ) { }

  public confirm(): void {
    if (this.rememberNotConfirmationFormControl.value === true && this.rememberId) {
      this.userSettingsService.addRememberNotConfirmation(this.rememberId);
    }
    this.confirmedEmitter.emit();
    this.modalRef.close();
  }

  @HostListener('click', ['$event.target'])
  public openModal(): void {

    if (this.rememberId && this.userSettingsService.isRememberNotConfirmation(this.rememberId)) {
      this.confirmedEmitter.emit();
      return;
    }
    this.modalRef = this.modalService.open(this.modal, {size: 'lg', centered: true, animation: false})
  }
}
