import {Inject, Pipe, PipeTransform} from '@angular/core';
import {FileUploaded, MediaResourceTypeEnum} from "@models/interfaces";

@Pipe({
  name: 'cdnFileUploadedResolver'
})
export class CdnFileUploadedResolverPipe implements PipeTransform {

  private directoryMap: Map<string, string> = new Map();

  public constructor(
      @Inject('MEDIA_SOURCE_CDN_URL') public host: string
  ) {
    this.directoryMap.set('image/jpeg', MediaResourceTypeEnum.Image);
    this.directoryMap.set('image/png', MediaResourceTypeEnum.Image);
    this.directoryMap.set('image/gif', MediaResourceTypeEnum.Image);
    this.directoryMap.set('image/webp', MediaResourceTypeEnum.Image);
    this.directoryMap.set('audio/mpeg', MediaResourceTypeEnum.Audio);
    this.directoryMap.set('video/mp4', MediaResourceTypeEnum.Video);
  }

  public transform(fileUploaded: FileUploaded|null): string {

    if (!fileUploaded) {
      return '';
    }
    let directory = MediaResourceTypeEnum.File as string;
    if (this.directoryMap.has(fileUploaded?.mimetype)) {
      directory = this.directoryMap.get(fileUploaded.mimetype) as string;
    }
    let url = this.host;
    url += '/';
    url += directory;
    url += '/';
    url += fileUploaded.filename;

    return url;
  }
}
