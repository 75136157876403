<div class="modal-header">
    <h6 class="modal-title">
        {{title}}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeEmitter.emit()"></button>
</div>
<div class="modal-body bg-light">
    <div class="container">
        <div class="row bg-white justify-content-center">
            <div class="col p-0">
                <div *ngIf="!loader; else spinner">
                    <ng-content select="[content]"></ng-content>
                </div>
                <ng-template #spinner>
                    <app-spinner></app-spinner>
                </ng-template>
            </div>
        </div>
    </div>
</div>
