import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconName} from "ngx-bootstrap-icons";

@Component({
  selector: 'app-medium-button',
  templateUrl: './medium-button.component.html',
  styleUrls: ['./medium-button.component.scss']
})
export class MediumButtonComponent {
  @Output() public trigger: EventEmitter<void> = new EventEmitter<void>();
  @Input() public text!: string;
  @Input() public icon!: IconName;
  @Input() public disable: boolean = false;
}
