import {BaseHttpService} from "./base-http.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Content, PublishableStatusType, Tour, WidgetLanguageReport} from "@models/interfaces";


@Injectable({
  providedIn: 'root'
})
export class ContentHttpService extends BaseHttpService {

  public create(content: Content): Observable<void> {
    return this.httpClient.post<void>(
      this.host + '/contents/create',
      content,
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public list(customerPlaceCode: string): Observable<Content[]> {
    return this.httpClient.get<Content[]>(
      this.host + '/contents/list/' + customerPlaceCode,
      {headers: this.getBasicRequestHeaders()}
    );
  }

  public details(uuid: string): Observable<Content> {
    return this.httpClient.get<Content>(
        this.host + '/contents/details/' + uuid,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public delete(uuid: string): Observable<void> {
    return this.httpClient.delete<void>(
        this.host + '/contents/delete/' + uuid,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public edit(content: Content): Observable<void> {
    return this.httpClient.put<void>(
        this.host + '/contents/edit',
        content,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public tours(uuid: string): Observable<Tour[]|null> {
    return this.httpClient.get<Tour[]|null>(
        this.host + '/contents/tours/' + uuid,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public reportMissingTranslations(uuid: string): Observable<WidgetLanguageReport[]> {
    return this.httpClient.get<WidgetLanguageReport[]>(
        this.host + '/contents/reports-missing-translations/' + uuid,
        {headers: this.getBasicRequestHeaders()}
    );
  }

  public changeStatus(uuid: string, status: PublishableStatusType): Observable<void> {
    return this.httpClient.put<void>(
        this.host + '/contents/change-status/' + uuid + '/' + status ,
        null,
        {headers: this.getBasicRequestHeaders()}
    );
  }
}
