import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-search-collection',
  templateUrl: './search-collection.component.html',
  styleUrls: ['./search-collection.component.scss']
})
export class SearchCollectionComponent<E> implements OnInit {

  @Input() public handler!: (element: E) => string|null;
  @Input() public collection!: E[];
  @Output() public collectionSearched: EventEmitter<E[]> = new EventEmitter<E[]>();

  public searchTimeOut!: any;
  public searchCriteriaFormControl: FormControl = new FormControl();
  private toFilter: E[] = [];

  public search(): void {
    if (this.searchTimeOut !== undefined) {
      clearTimeout(this.searchTimeOut);
    }
    this.searchTimeOut = setTimeout(() => {

      const results: E[] = this.toFilter.filter((element: E) => {
        if (this.searchCriteriaFormControl.value.toString().trim().length === 0) {
          return true;
        }
        const textToCompare: string|null = this.handler(element);

        if (!textToCompare) {
          return false;
        }
        return this.normalizeText(textToCompare).includes(this.normalizeText(this.searchCriteriaFormControl.value))
      });
      this.collectionSearched.emit(results);
    }, 1000);
  }

  public ngOnInit(): void {
    this.toFilter = this.collection;
  }

  private normalizeText(input: string): string {

    /**
     * TODO: Possible issue with arabian, chinese, russian or other language types.
     */
    return input.toLowerCase()
        .replace(new RegExp(/á+/g), 'a')
        .replace(new RegExp(/é+/g), 'e')
        .replace(new RegExp(/í+/g), 'i')
        .replace(new RegExp(/ó+/g), 'o')
        .replace(new RegExp(/ú+/g), 'u')
        .replace(new RegExp(/\s+/g), '-')
        .replace(new RegExp(/[^a-z\d\-]+/g), '');
  }
}
