import {Component, Input, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-view-wrapper',
  templateUrl: './view-wrapper.component.html',
  styleUrls: ['./view-wrapper.component.scss']
})
export class ViewWrapperComponent implements OnInit {

  @Input() public loader!: boolean;
  @Input() public title!: string;

  public constructor(
      private titleService: Title
  ) {
  }

  public ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }
}
