import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mediaDurationDisplay'
})
export class MediaDurationDisplayPipe implements PipeTransform {

  public transform(input: number): string {
    const minutes: number = Math.floor(input / 60);
    const seconds: number = Math.floor(input % 60);
    let value = minutes.toString();
    // Set time type.
    if (minutes < 1) {
      value += ':' + seconds.toString();
      value += ' segundos';
    } else {
      value += ':' + seconds.toString();
      value += ' minutos';
    }
    return value;
  }
}
