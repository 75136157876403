import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconName} from "ngx-bootstrap-icons";

@Component({
  selector: 'app-small-button',
  templateUrl: './small-button.component.html',
  styleUrls: ['./small-button.component.scss']
})
export class SmallButtonComponent {
  @Output() public trigger: EventEmitter<void> = new EventEmitter();
  @Input() public text!: string;
  @Input() public icon!: IconName;
  @Input() public disable: boolean = false;
  @Input() public loader: boolean = false;
}
