import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {PublishableStatusType} from "@models/interfaces";

@Component({
  selector: 'app-status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss']
})
export class StatusSelectorComponent implements OnInit {

  @Output() public changeStatus: EventEmitter<PublishableStatusType> = new EventEmitter();
  @Input() public field: FormControl = new FormControl();
  @Input() public default!: PublishableStatusType;
  public options: PublishableStatusType[] = [];

  public ngOnInit(): void {
    this.options = [
        'disabled',
        'developing',
        'pending_to_review',
        'qa',
        'public'
    ];
    if (this.default) {
      this.field.setValue(this.default);
    }
  }
}
