import { Component, OnInit } from '@angular/core';
import {UserSettingsService} from "@services/user-settings.service";
import {CustomerPlaceHttpService} from "@services/http/customer-place-http.service";
import {CustomerPlace} from "@models/interfaces";
import {UserHttpService} from "@services/http/user-http.service";
import {Router} from "@angular/router";
import {Role, User} from "@models/interfaces";

@Component({
  selector: 'app-navigation-bar-manager-menu',
  templateUrl: './navigation-bar-manager-menu.component.html',
  styleUrls: ['./navigation-bar-manager-menu.component.scss']
})
export class NavigationBarManagerMenuComponent implements OnInit {

  public customerPlaceCurrentCode: string|null = null;
  public customerPlaceCurrentName: string = '';
  public isAdmin: boolean = false;

  public constructor(
      private userSettingsService: UserSettingsService,
      private userHttpService: UserHttpService,
      private customerPlaceHttpService: CustomerPlaceHttpService,
      private router: Router
  ) { }

  public ngOnInit(): void {
    this.userSettingsService.onCustomerPlaceSelection.subscribe(_ => {
      this.preset();
    });
    this.preset();
  }

  private preset(): void {
    this.customerPlaceCurrentCode = this.userSettingsService.getCustomerPlaceSelected();
    if (this.customerPlaceCurrentCode) {
      this.customerPlaceHttpService.details(this.customerPlaceCurrentCode).subscribe({
        next: (response: CustomerPlace) => {
          this.customerPlaceCurrentName = response.name;
        }
      });
    }
    this.userHttpService.current().subscribe({
      next: (response: User) => {
        const role = response.roles.find((role: Role) => role.name === 'admin');
        if (role) {
          this.isAdmin = true;
        }
      }
    });
  }

  public customerPlaceSelection(): void {
    this.customerPlaceCurrentName = '';
    this.customerPlaceCurrentCode = null;
    this.userSettingsService.clear();
    this.router.navigate(['manager', 'customer-place', 'selection']);
  }
}
