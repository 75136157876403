import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-view-wrapper-modal',
  templateUrl: './view-wrapper-modal.component.html',
  styleUrls: ['./view-wrapper-modal.component.scss']
})
export class ViewWrapperModalComponent {

  @Input() public loader: boolean = false;
  @Input() public title!: string;
  @Output() public closeEmitter: EventEmitter<void> = new EventEmitter<void>();
}
