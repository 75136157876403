<div class="collapse navbar-collapse">
    <ul class="navbar-nav border-white">
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'person-lines-fill',
            title: 'managementUsers' | translate,
            tooltip: 'users' | translate,
            link: '/admin/users/list'
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'collection',
            title: 'managementTemplates' | translate,
            tooltip: 'templates' | translate,
            link: '/admin/template/list'
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'geo',
            title: 'managementCustomerPlaces' | translate,
            tooltip: 'customerPlaces' | translate,
            link: '/admin/customer-place/list'
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'phone',
            title: 'managementApiClients' | translate,
            tooltip: 'apiClients' | translate,
            link: '/admin/api/clients'
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'bar-chart-line',
            title: 'managementStatistics' | translate,
            tooltip: 'statisticsManagement' | translate,
            link: '/admin/statistics/list'
        }"></ng-container>
        <ng-container *ngTemplateOutlet="listElement; context: {
            icon: 'layout-text-window',
            title: 'managementContents' | translate,
            tooltip: 'contentsManagement' | translate,
            link: '/manager/customer-place/selection'
        }"></ng-container>
    </ul>
</div>
<ng-template #listElement let-icon="icon" let-title="title" let-tooltip="tooltip" let-link="link">
    <li class="nav-item small">
        <a class="nav-link text-white mx-2"
           [routerLink]="link"
           [ngbTooltip]="tooltip"
           [title]="title"
           placement="bottom"
           tooltipClass="tooltip-custom">
            <i-bs [name]="icon" width="22" height="22"></i-bs>
        </a>
    </li>
</ng-template>


