import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-help-info-hover',
  templateUrl: './help-info-hover.component.html',
  styleUrls: ['./help-info-hover.component.scss']
})
export class HelpInfoHoverComponent {
  @Input() public textHelp!: string;
}
