import {Component, Inject, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public constructor(
      @Inject('VERSION') public version: string,
      private title: Title,
      private translateService: TranslateService
  ) {
  }

  public ngOnInit(): void {
    console.log(`%c Running version: ${this.version}`, 'color: green; font-weight: bold; font-size: 15px;');
    this.translateService.get('tabTitle.default').subscribe(
        (value: string) => {
          this.title.setTitle(value);
        }
    );
  }
}
