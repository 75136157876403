<div class="p-3" *ngIf="!loader; else spinner">
    <div>
        <form [formGroup]="form" (ngSubmit)="submit()" #formElement="ngForm">
            <div class="form-group">
                <label for="description" class="form-label text-secondary required small">
                    {{'description' | translate}}
                </label>
                <input type="text" class="form-control form-control-sm" [ngClass]="{'is-invalid': form.get('description')?.invalid&&formElement.submitted}" id="description" formControlName="description" maxlength="128" />
                <div class="invalid-feedback small">
                    <p *ngIf="form.get('description')?.hasError('required')">
                        {{'mandatoryValue' | translate}}
                    </p>
                </div>
            </div>
            <div class="form-group mt-2">
                <input class="form-check-input" type="checkbox" formControlName="default" id="default">
                <label class="form-label text-secondary small mx-2" for="default">
                    {{ 'default' | translate }}
                    <app-help-info-hover [textHelp]="'infoMessage.categoryDefault' | translate"></app-help-info-hover>
                </label>
            </div>
        </form>
    </div>
    <div class="p-3 d-flex justify-content-end">
        <app-medium-button [text]="'save' | translate" [icon]="'save'" (trigger)="formElement.ngSubmit.emit()"></app-medium-button>
    </div>
</div>
<ng-template #spinner>
    <app-spinner></app-spinner>
</ng-template>
