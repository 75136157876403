import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as L from 'leaflet';
import {icon, latLng, LatLngLiteral, Layer, LeafletMouseEvent, marker} from "leaflet";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'app-gps-location-selector',
  templateUrl: './gps-location-selector.component.html',
  styleUrls: ['./gps-location-selector.component.scss']
})
export class GpsLocationSelectorComponent implements OnInit {

  @Output() public locationChanged: EventEmitter<string> = new EventEmitter();
  @Input() public geojson!: string;

  public form: FormGroup = new FormGroup({
    'latitude': new FormControl(null, [Validators.required, Validators.min(-90), Validators.max(90)]),
    'longitude': new FormControl(null, [Validators.required, Validators.min(-180), Validators.max(180)])
  });
  public invalidGeojsonFormatError: boolean = false;
  public currentPosition!: LatLngLiteral;
  public setManually: boolean = false;

  public options: L.MapOptions = {
    layers: [
      new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      } as L.TileLayerOptions),
    ],
    center: latLng(40.40120120183825, -3.8008906147128836),
    zoom: 6,
  };
  public marker!: Layer;

  public ngOnInit(): void {
    if (this.geojson.length) {
      if (!/^{"type":"(Point)","coordinates":\[.*\]}$/.test(this.geojson)) {
        this.invalidGeojsonFormatError = true;
        return;
      }
      const geojson = JSON.parse(this.geojson);
      this.currentPosition = latLng(geojson.coordinates[1], geojson.coordinates[0]);
      this.options.center = this.currentPosition;
      this.setMarker();
      this.setFormValues();
    }

    this.form.valueChanges.subscribe((form: { latitude: any; longitude: any; }) => {
      if (this.form.invalid) {
        return;
      }
      this.selectCoordinates(latLng(form.latitude, form.longitude))
      this.setMarker();
    });
  }

  public mapSelect(event: LeafletMouseEvent): void {
    this.invalidGeojsonFormatError = false;
    this.selectCoordinates(event.latlng);
    this.setFormValues();
    this.setMarker();
  }

  private selectCoordinates(coordinates: LatLngLiteral): void {
    this.currentPosition = coordinates;
    this.locationChanged.emit(JSON.stringify({
      type: 'Point',
      coordinates: [this.currentPosition.lng, this.currentPosition.lat]
    }));
  }

  public setMarker(): void {
    this.marker = marker(this.currentPosition, {
      icon: icon({
        iconUrl: 'assets/images/marker.png',
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      })
    });
  }

  private setFormValues(): void {
    this.form.get('latitude')?.setValue(this.currentPosition.lat);
    this.form.get('longitude')?.setValue(this.currentPosition.lng);
  }

  public onMapReady(map: L.Map): void {
    setTimeout(() => {
      map.invalidateSize();
    }, 500);
  }
}
