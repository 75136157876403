import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Category} from "@models/interfaces";
import CategoryHttpService from "@services/http/category-http.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlingService} from "@services/error-handling.service";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  @Output() public categorySelected: EventEmitter<Category> = new EventEmitter();

  public categories: Category[] = [];
  public loader: boolean = false;

  public constructor(
      private categoryHttpService: CategoryHttpService,
      private errorHandlingService: ErrorHandlingService
  ) { }

  public ngOnInit(): void {
    this.loader = true;
    this.categoryHttpService.useCache(false).list().subscribe({
      next: (response: Category[]) => {
        this.categories = response;
        this.loader = false;
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error)
    });
  }
}
