import {Component, OnInit, TemplateRef} from '@angular/core';
import {Toast, ToastService} from "@services/toast.service";

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent {

  public constructor(
      public toastService: ToastService
  ) { }

  public isTemplate(toast: Toast): boolean {
    return toast.textOrTemplate instanceof TemplateRef;
  }
}
