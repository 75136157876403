import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlingService {

    public constructor(
        private router: Router,
        private modalService: NgbModal
    ) {
    }

    public handleHttp(error: HttpErrorResponse): void {
        this.modalService.dismissAll();
        this.router.navigate(['error', error.status]);
    }
}
