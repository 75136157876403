import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextShortenerPipe} from './text-shortener.pipe';
import {DateUtcToLocalRawFormatPipe} from './date-utc-to-local-raw-format.pipe';
import {CdnFileUploadedResolverPipe} from "./cdn-file-uploaded-resolver.pipe";
import { FileSizeRawFormatterPipe } from './file-size-raw-formatter.pipe';
import { MediaDurationDisplayPipe } from './media-duration-display.pipe';


@NgModule({
    declarations: [
        TextShortenerPipe,
        DateUtcToLocalRawFormatPipe,
        CdnFileUploadedResolverPipe,
        FileSizeRawFormatterPipe,
        MediaDurationDisplayPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        TextShortenerPipe,
        DateUtcToLocalRawFormatPipe,
        CdnFileUploadedResolverPipe,
        FileSizeRawFormatterPipe,
        MediaDurationDisplayPipe
    ]
})
export class PipesModule {
}
