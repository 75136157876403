import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable, of, tap} from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  private cache: Map<string, HttpResponse<any>> = new Map();

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method !== 'GET') {
      return next.handle(req);
    }
    if (req.headers.get('Cache-Control') === 'no-store') {
      this.cache.delete(req.url);
    }
    const cachedResponse: HttpResponse<any>|undefined = this.cache.get(req.urlWithParams);
    if (cachedResponse !== undefined) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(req).pipe(
          tap(
              (response: HttpEvent<any>) => {
                if (response instanceof HttpResponse) {
                  this.cache.set(req.url, response);
                }
              }
          )
      );
    }
  }
}
