import {Component, ElementRef, EventEmitter, HostListener, Output, ViewChild} from '@angular/core';
import {Content} from "@models/interfaces";
import {ContentHttpService} from "@services/http/content-http.service";
import {UserSettingsService} from "@services/user-settings.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlingService} from "@services/error-handling.service";

@Component({
  selector: 'app-content-selector',
  templateUrl: './content-selector.component.html',
  styleUrls: ['./content-selector.component.scss']
})
export class ContentSelectorComponent {

  @Output() public contentSelected: EventEmitter<Content> = new EventEmitter<Content>();
  @ViewChild('contentSelectionModal') public modal!: ElementRef;

  public contents: Content[] = [];
  public loader: boolean = true;
  public modalRef!: NgbModalRef;

  public constructor(
      private contentHttpService: ContentHttpService,
      private userSettingsService: UserSettingsService,
      private modalService: NgbModal,
      private errorHandlingService: ErrorHandlingService
  ) {
  }

  public searchHandler(content: Content): string {
    return content.description;
  }

  @HostListener('click', ['$event.target'])
  public openModal(): void {
    this.modalRef = this.modalService.open(this.modal, {centered: true, animation: false, size: 'lg'});
    this.loader = true;
    this.contentHttpService.useCache(false).list(this.userSettingsService.getCustomerPlaceSelected() as string).subscribe({
      next: (response: Content[]) => {
        this.contents = response;
        this.loader = false;
      },
      error: (error: HttpErrorResponse) => this.errorHandlingService.handleHttp(error)
    });
  }
}
