import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public code!: number;

  public constructor(
      private route: ActivatedRoute,
      private location: Location
  ) { }

  public ngOnInit(): void {
    this.code = this.route.snapshot.params['code'] == 0 ? 500 : this.route.snapshot.params['code'];
  }

  public goBack(): void {
    this.location.back();
  }
}
