<div class="container-fluid vh-100">
    <div class="row align-items-center justify-content-center">
        <div class="col-xl-3 col-lg-3 col-md-8 col-sm-8 col-xs-12 bg-white border-secondary p-3 my-5">
            <div *ngIf="!loader; else spinner">
                <div class="text-center my-5">
                    <img src="./assets/images/logo.png" alt="Logo GVAM" class="logo" />
                </div>
                <div class="alert alert-danger small" *ngIf="formLogin.invalid && submitted">
                    {{loginValidationErrorMessage}}
                </div>
                <div class="alert alert-danger small" *ngIf="loginServerError">
                    {{'errorLoginServer' | translate}}
                </div>
                <form [formGroup]="formLogin" (keydown)="keydownHandler($event)" (ngSubmit)="submit()" #formElement="ngForm">
                    <div class="my-2">
                        <label for="username" class="form-label text-secondary small">
                            {{'username' | translate}}
                        </label>
                        <input type="text" class="form-control form-control-sm" maxlength="128" id="username" formControlName="username" />
                    </div>
                    <div class="my-2">
                        <label for="password" class="form-label text-secondary small">
                            {{'password' | translate}}
                        </label>
                        <input type="password" class="form-control form-control-sm" maxlength="128" id="password" formControlName="password" />
                    </div>
                    <div class="py-3 row text-center">
                        <app-medium-button [text]="'login' | translate" [icon]="'box-arrow-in-left'" (trigger)="formElement.ngSubmit.emit()"></app-medium-button>
                    </div>
                </form>
                <!-- TODO: Remember password -->
            </div>
            <ng-template #spinner>
                <div class="row p-4 justify-content-center align-items-center">
                    <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">
                        {{'loading' | translate}}
                    </span>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="version-display text-success small fw-bold p-3">
            {{'versionDisplay' | translate : {version: version} }}
        </div>
    </div>
</div>

